import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/destination'

class DestinationService extends ApiService {
    constructor(){
        super()
    }
    getAuthUrl(destination_id){
        return http.get(`${this.getUrl()}/${destination_id}/get-auth-url`)
    }
    getUrl(){
        return URL
    }
    handleCallback(code, state){
        return http.post(`${this.getUrl()}/callback`, {
            "code": code,
            "state": state
        })
    }

    
}

export default new DestinationService()