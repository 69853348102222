import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/customer'

class CustomerService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getStatusses(){
        return http.get(`${this.getUrl()}-status`)
    }
    getPartners(){
        return http.get(`${this.getUrl()}-partner`)
    }
}

export default new CustomerService()